import "./App.css";
import AboutUs from "./components/AboutUs";

import Navbar from "./components/Navbar";

import Foot from "./components/Foot";
import History from "./components/History";

import Allcards from "./components/Allcards";

function App() {
  return (
    <div>
      <Navbar />

      <AboutUs />

      <Allcards />

      <Foot />
    </div>
  );
}

export default App;
