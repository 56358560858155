function AboutUs() {
  return (
    <div className="">
      <img
        className="rounded-t-xl w-[110%]"
        src="https://fyjtesykjrcgqhozoncx.supabase.co/storage/v1/object/public/cabin-images/sm_tiling3.png"
        alt="Team tailwind section"
      />
      <div>
        <div className="">
          <h2 className="text-center text-3xl font-semibold h-2">
            SM Tiling's History
          </h2>
          <hr class="w-48 h-1 mx-auto my-4 bg-gray-100 border-0 rounded md:my-10 dark:bg-gray-700 mt-10"></hr>
        </div>
        <div className="text-center mb-8">
          SM Tiling is a leading tiles installation services company based in
          Cape Town , South Africa.
        </div>
        <div className=" text-center">
          Our core services include: <div>1. Tiles installation services</div>
          <div>2. Floor repairs</div>
        </div>
      </div>
      <h2 className="pt-6 pb-4 text-center text-4xl font-semibold shadow">
        Our past projects
      </h2>
    </div>
  );
}

export default AboutUs;
