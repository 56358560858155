import Card from "./content";

function Allcards() {
  const data = [
    {
      description: "Shower tiling",
      image: "images/tile1.jpg",
    },
    {
      description: "kitchen counter tiling",
      image: "images/tile2.jpg",
    },
    {
      description: "stairs tiling",
      image: "images/tile3.jpg",
    },
    {
      description: "morden kitchen counter tiling",
      image: "images/tile4.jpg",
    },
    {
      description: "floor tiling",
      image: "images/tile5.jpg",
    },
    {
      description: "wall tiling",
      image: "images/tile6.jpg",
    },
    {
      description: "bathroom tiling",
      image: "images/tile7.jpg",
    },
    {
      description: "bathroom tiling",
      image: "images/tile8.jpg",
    },
    {
      description: "wooden floor tiling",
      image: "images/tile9.jpg",
    },
    {
      description: "toilet wall tiling",
      image: "images/tile10.jpg",
    },
    {
      description: "bathroom tiling",
      image: "images/tile11.jpg",
    },
    {
      description: "floor tiling",
      image: "images/tile12.jpg",
    },
    {
      description: "kitchen wall tiling",
      image: "images/tile13.jpg",
    },
    {
      description: "floor tiling",
      image: "images/tile14.jpg",
    },
    {
      description: "shower tiling",
      image: "images/tile15.jpg",
    },
    {
      description: "flooir tiling",
      image: "images/tile16.jpg",
    },
    {
      description: "stairs tiling",
      image: "images/tile17.jpg",
    },
    {
      description: "kitchen tiling",
      image: "images/tile18.jpg",
    },
    {
      description: "kitchen tiling",
      image: "images/tile19.jpg",
    },
    {
      description: "bathroom tiling",
      image: "images/tile20.jpg",
    },
  ];

  return (
    <div className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 sm:gap-1  space-x-4 place-items-center">
      {data.map((carElement) => {
        return <Card cardElement={carElement} />;
      })}
    </div>
  );
}

export default Allcards;
