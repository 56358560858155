function Card({ cardElement }) {
  console.log(cardElement);
  return (
    <div className="grid-cols-">
      <div className=" mt-6 mb-6  w-64 h-80 rounded-sm  , bg-gray-100 shadow-2xl  ">
        <img className="h-64 w-64" src={cardElement.image} alt="u" />
        <p>{cardElement.description}</p>
      </div>
    </div>
  );
}
export default Card;
